import * as React from "react";
import "./BlogRow.css"

const BlogRow = ({image,title,timestamp,description}) => {

    return(
     <div className="blogRow">
         <img src={image} alt=""/>
         <div className="blogRowText">
          <h3>{title}</h3>
          <p>{timestamp}</p>
          <p>  {description}</p>
          </div>
    
            
          </div>

    );




}

export default BlogRow